<div class="login-box">
  <div class="card">
    <form>
      <legend class="matLabel mb-3">Login</legend>
      <div class="p20">
        <input class=" textInput mt-3 mb-3" [ngModelOptions]="{standalone: true}" type="text"
          placeholder="Enter Username" [(ngModel)]="loginObj.username">
        <input class=" textInput mt-3 mb-3" [ngModelOptions]="{standalone: true}" type="password"
          placeholder="Enter Password" [(ngModel)]="loginObj.password">
        <button class="mt-3 mb-3  btn-blue" (click)="onSubmit()">Login</button>
        <button class="fr changepwdBtn" (click)="openChangePwdDialog()">Forgot Password?</button>
      </div>
    </form>
  </div>
</div>

<p-dialog [(visible)]="loginObj.showPasswordDialog" header="Change Password">
  <div>
    <input class="form-control textInput mt-3 mb-3" type="text" placeholder="Enter Phone Number" required
      [(ngModel)]="loginObj.phNo">
    <input class="form-control textInput mt-3 mb-3" type="password" placeholder="Enter Old Password" required
      [(ngModel)]="loginObj.oldPassword">
    <input class="form-control textInput mt-3 mb-3" type="password" placeholder="Enter New Password" required
      [(ngModel)]="loginObj.newPassword">
    <input class="form-control textInput mt-3 mb-3" type="password" placeholder="Enter Confirm Password" required
      [(ngModel)]="loginObj.confirmPassword">
  </div>
  <div>
    <button class="btn-blue fr" cdkFocusInitial (click)="changePassword()">Change Password</button>
    <button class="btn-red fr" (click)="closeDialog()">Cancel</button>
  </div>
</p-dialog>

<app-loader [showSpinner]="loginObj.showLoader"></app-loader>