import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  user: any = [];
  showSideBar = false;

  public menuItems: MenuItem[] = [
    {
      title: 'ONN Bikes Dashboard',
      link: '/main/downtime-report',
      roles: [Roles.ADMIN],
    },
    {
      title: 'Dashboard',
      link: '/main/user-lead-status-count',
      roles: [Roles.ADMIN],
    },
    {
      title: 'Leads',
      link: '/main/view-leads',
      roles: [Roles.ADMIN, Roles.SalesUser],
    },
    {
      title: 'Users',
      link: '/main/users',
      roles: [Roles.ADMIN],
    },
    {
      title: 'Guided Tours',
      link: '/main/explore-places',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Trails',
      link: '/main/trails',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Station',
      link: '/main/station',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Master',
      link: '/main/master',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Partner Dashboard 1.5',
      link: '/main/dashboard-financial',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Warnings',
      link: '/main/warnings',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Points',
      link: '/main/points',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Stations',
      link: '/main/stations',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Partner',
      link: '/main/partner-list',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    // {
    //   title: 'Partner Dashboard 2.0',
    //   link: '/main/partner-bookingdashboard',
    //   roles: [Roles.Partner_Admin, Roles.ADMIN],
    // },
    {
      title: 'Tours & Trails',
      link: '/main/curated-list',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Add New Booking',
      link: '/main/add-booking',
      roles: [Roles.ADMIN, Roles.Partner_Admin],
    },
    {
      title: 'Business-Dashboard',
      link: '/main/business-dashboard',
      roles: [Roles.ADMIN, Roles.Partner_Admin],
    },
    {
      title: 'Bookings',
      link: '/main/bookings',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Rides',
      link: '/main/rides',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Web Story',
      link: '/main/web-story',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Blogs',
      link: '/main/blogs',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Journals',
      link: '/main/journals',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Destination',
      link: '/main/destination',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Neighbourhoods',
      link: '/main/neighbourhoods',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Carousels',
      link: '/main/carousels',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
    {
      title: 'Web Leads',
      link: '/main/web-leads',
      roles: [Roles.ADMIN, Roles.ContentModerator],
    },
  ];
  constructor(private router: Router) { }
  hidetopbar = false;
  ngOnInit(): void {
    this.user = localStorage.getItem('user_data');

    if (this.user) {
      this.user = JSON.parse(this.user);
    }
    this.menuItems = this.menuItems.filter((p) => this.hasPermission(p));

    if (window.location.href.includes('main/terms-and-condition')) {
      this.hidetopbar = true;
    } else {
      this.hidetopbar = false;
    }
  }

  state: string = '>';
  opened = true;
  navbarState(state: any) {
    if (state === 'opened') this.state = '<';
    else if (state === 'closed') this.state = '>';
  }

  hasPermission(menu: MenuItem): boolean {
    return menu.roles.some((p) => this.user.roles.includes(p));
  }

  redirectTo(menu) {
    this.showSideBar = false;
    this.router.navigate([menu]);
  }
}

export interface MenuItem {
  title: string;
  link: string;
  roles: string[];
}

export enum Roles {
  ADMIN = 'ADMIN',
  ServiceUser = 'Service User',
  SalesUser = 'Sales User',
  ContentCreator = 'Content Creator',
  ContentModerator = 'Content Moderator',
  Partner_Admin = 'Partner_Admin',
}
