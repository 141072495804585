import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  public showSuccessMessage(message: string) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      text: message,
      confirmButtonText: "Close",
      title: 'Success',
      showConfirmButton: false,
      timer: 1500
    })

  }


  public showFailMessage(message: string) {
    Swal.fire({
      title: "Failure",
      text: message,
      confirmButtonText: "Close",
      icon: "warning"

    });
  }

  public showConfirmationMessageWithRedirectURL(
    subject: string,
    message: string,
    type: string,
    goToURL: string
  ): any {
    return Swal.fire({
      title: subject,
      text: message,
      icon:
        type == "warning"
          ? "warning"
          : type == "success"
            ? "success"
            : type == "info"
              ? "info"
              : "question",
      showCancelButton: false,
      confirmButtonText: "Ok",
      cancelButtonColor: "#3f6ad8",
    }).then((result) => {
      if (result.value) {
        this.router.navigate([goToURL]);
      }
    });
  }

  public SomethingWentWrong() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
    })
  }

  public AreYouSure(message:string):any{
  return Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes"
    })
  }

 /*  public ConfirmAgain(message:string) {
    Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Im sure!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/main/users']);
        Swal.fire(
          'Done!',
          'Lead is now user.',
          'success'
        )
      } 
    })
  } */

 

}