<div class="h-66" *ngIf="!hidetopbar">
  <button class="hamburger" (click)="showSideBar = true"><i class="fa fa-bars"></i></button>
</div>
<p-sidebar [(visible)]="showSideBar" [baseZIndex]="10000">
  <a *ngFor="let menu of menuItems" class="navLinks cursor-pointer" (click)="redirectTo(menu.link)">
    {{menu.title}}</a>

  <!--  <a class="navLinks" [routerLink]="['/main/terms-and-condition']"> Terms And Condition</a> -->
  <div class="dropdown" (click)="showSideBar = false" [hidden]="user.roles.includes('Partner_Admin')">
    <button class="dropbtn">Inventory</button>
    <div id="myDropdown" class="dropdown-content">
      <a routerLink="/main/view-vehicles-list">Vehicle/Bikes</a>
      <!--  <a href="#Telematics">Telematics</a> -->
      <a routerLink="/main/sim-list">Networking</a>
      <!--  <a href="#Battery">Battery</a>
      <a href="#Accessories">Accessories</a>
      <a href="#SafetyEquipment">Safety Equipment</a> -->
    </div>
  </div>
  <hr>
  <a class="navLinks" [routerLink]="['']"> Logout</a>
</p-sidebar>
<router-outlet></router-outlet>