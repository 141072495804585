import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '../app-routing.module';
import { ThemeModule } from '../shared/theme/theme.module';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: LoginPageComponent
      },
      { path: 'login', component: LoginPageComponent }
    ]
  },

];

const PrimengImports = [DialogModule];


@NgModule({
  declarations: [
    LoginPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    //  ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ThemeModule,
    PrimengImports
  ],
  exports: [
    LoginPageComponent
  ]
})
export class AuthenticationModule { }
