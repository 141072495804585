import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LeadServiceService } from 'src/app/lead-service.service';
import { UtilityService } from 'src/app/shared/utility.service';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {


  userData: any;

  public loginObj = {
    username: '',
    password: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    phNo: '',
    showLoader: false,
    showPasswordDialog: false
  };

  constructor(private utilityService: UtilityService,
    private leadService: LeadServiceService,
    private router: Router) { }

  ngOnInit(): void { }

  onSubmit() {
    /* if (this.loginObj.username == 'admin' && this.loginObj.password == 'admin') */
    const payload = {
      mobileNumber: this.loginObj.username,
      password: this.loginObj.password
    }
    this.loginObj.showLoader = true;
    this.leadService.login(payload).subscribe((response: any) => {
      if (response.code === 'SUCCESS') {
        console.log("Login Successful!");
        this.userData = response.data;
        localStorage.removeItem("user_data");
        localStorage.setItem("user_data", JSON.stringify(this.userData));
        if (response.data.roles.includes("Partner_Admin")) {
          this.router.navigate(['/main/business-dashboard']);
        } else {
          this.router.navigate(['/main/view-leads']);
        }
      } else {
        this.utilityService.showFailMessage(response.message);
        this.router.navigate(['auth/login']);
      }
      this.loginObj.showLoader = false;
    }, (error) => {
      this.utilityService.SomethingWentWrong();
      this.loginObj.showLoader = false;
    });

  }

  openChangePwdDialog() {
    // this.matDialog.open(pwdDialog, { height: '45%', width: '30%' });
    this.loginObj.showPasswordDialog = true;
  }

  closeDialog() {
    // this.matDialog.closeAll();
    this.loginObj.showPasswordDialog = false;
  }

  changePassword() {
    if (this.loginObj.newPassword != '' && this.loginObj.confirmPassword != '') {
      if (this.loginObj.newPassword === this.loginObj.confirmPassword) {
        let payload = {
          mobileNumber: this.loginObj.phNo,
          oldPassword: this.loginObj.oldPassword,
          newPassword: this.loginObj.newPassword,
          confirmPassword: this.loginObj.confirmPassword
        }
        this.leadService.ChangePwd(payload).subscribe((response: any) => {
          if (response.code === 'SUCCESS') {
            this.utilityService.showConfirmationMessageWithRedirectURL("SUCCESS", response.data, 'success', 'auth/login');
            // this.matDialog.closeAll();
            this.loginObj.showPasswordDialog = false;
          } else {
            this.utilityService.showFailMessage(response.data);
            this.router.navigate(['auth/login']);
          }
        }, (error) => {
          this.utilityService.SomethingWentWrong();
        });
      } else {
        this.utilityService.showFailMessage("Password do not match!")
      }
    } else {
      this.utilityService.showFailMessage("Please enter the data")
    }
  }
}
