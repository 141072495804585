import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './authentication/login-page/login-page.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

const routes: Routes = [
  /*  { path: '', redirectTo: "/auth", pathMatch: 'full' },
   {
     path: 'auth', component: BlankComponent, children: [
       {
         path: '',
         loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
       },
     ]
   }, */
  {
    path: 'auth', component: LoginPageComponent
  },
  {
    path: 'main', component: SidebarComponent, children: [
      {
        path: '',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
      },
    ]
  },
  { path: '**', redirectTo: '/auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
