import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'leadManagementSystem';
  hidetopbar = false;

  constructor(private router: Router) { }

  ngOnInit() {

    if (window.location.href.includes('main/terms-and-condition')) {
      this.hidetopbar = true;
    } else {
      this.hidetopbar = false;
    }
  }



  /* 
    state:string=">";
    opened =false;
    navbarState(state:any)
    {
      if(state==='opened')
      this.state= '<';
      else if(state==='closed')
      this.state= '>';
    } */
}
