import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LeadServiceService {
  private SERVER_URL = "https://strode-crm-backend.herokuapp.com";
  private tourService = " https://pwdbooking.onnbikes.com/tour-service/";

  constructor(private httpClient: HttpClient) { }

  //for login
  login(loginData: any) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI').set('Authorization', 'abcs');
    return this.httpClient.post(this.tourService + "login/login/v2", loginData, { headers });
  }

  //to Change the pwd
  ChangePwd(changepwdData: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/users/changePassword/v1", changepwdData);
  }

  //displays all the leads
  public getAllLeads(data: any) {
    //  return this.httpClient.get(this.SERVER_URL + "/strodecrm/leads/v1/" + offset + '/' + pageSize);
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/leads/list/v1", data);
  }

  //displays the total lead count
  public getTotalLeadcount() {
    return this.httpClient.get(this.SERVER_URL + "/strodecrm/leads/countLeads/v1");
  }

  //upload lead File excel
  public upLoadFile(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/leads/importLeads/v1", + data);
  }

  //saves new lead
  public saveNewLead(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/leads/v1", data);
  }

  //to edit lead info based on leadName
  updateLead(leadName: string, data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/leads/updateLead/v1/" + leadName, data);
  }

  //display user & lead status count
  displayLeadAndUserStatus() {
    return this.httpClient.get(this.SERVER_URL + "/strodecrm/leads/report/v1");
  }

  //to load dropdown of users in add lead pg
  loadUsers() {
    return this.httpClient.get(this.SERVER_URL + "/strodecrm/users/v1");
  }

  //gets the activities  for selected leadId
  getActivityData(leadId: any) {
    return this.httpClient.get(this.SERVER_URL + "/strodecrm/activity/v1/" + leadId);
  }
  //to add new activity inbound or outbound
  SaveNewActivity(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/activity/v1/", data);
  }

  //to add new Task
  addNewTask(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/activity/task/v1", data);
  }

  //to Display tasks for selected lead
  getTaskData(leadId: any) {
    return this.httpClient.get(this.SERVER_URL + "/strodecrm/activity/tasks/v1/" + leadId);
  }

  //to update the status of task (completed,pending,overdue)
  changeTaskStatus(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/activity/updateTaskStatus/v1", data);
  }

  //to convert lead to customer, gets lead data
  convertLeadToCustomer(leadId: any) {
    return this.httpClient.get(this.SERVER_URL + "/strodecrm/leads/getLeadToCustomer/v1/" + leadId);
  }

  //to search leads,when no. is clicked from dashborad pg
  searchLeads(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/leads/list/v1", data);
  }

  //to search tasks,when no. is clicked from dashborad
  searchTasks(data: any) {
    return this.httpClient.post(this.SERVER_URL + "/strodecrm/activity/searchTasks/v1/", data);
  }

  //to get record of selected number from dashboard
  private data: any;
  setData(leadData: any) {
    this.data = leadData;
  }
  getData() {
    let temp = this.data;
    return temp;
  }
}
